import React from "react";
import "./FeaturesSectionNew.css";
import Lottie from 'lottie-react';

import growthAnimation from '../assets/growth2.json';
import washSaleAvoidanceAnimation from '../assets/wash_sale_avoidance.json';
import harvestingOpportunities from '../assets/tax_optimization.json';
import dataSecurityAnimation from '../assets/security_lock.json';

function FeaturesSectionNew() {
    const features = [
        {
            title: "Security is our top priority",
            subtitle: "Your Data is Safe",
            description: "We connect to brokerages via SnapTrade, a trusted leader in secure data integration. Your financial data is protected with advanced encryption, ensuring it remains private and secure. ProfiTree never stores your login credentials, and SnapTrade establishes a read-only connection with your brokerage accounts to keep your information safe.",
            animation: dataSecurityAnimation, // Replace with the actual path to your image
        },
        {
            title: "Current Tax Loss Harvesting Opportunities",
            subtitle: "For Traders & Long-term Investors",
            description: "Gain personalized insights into tax-loss harvesting opportunities to optimize your portfolio's tax efficiency. ProfiTree analyzes your investments to identify assets that can be strategically sold to reduce your taxable income. These actionable insights help both active traders and long-term investors maximize their returns.",
            animation: growthAnimation, // Replace with the actual path to your image
        },
        {
            title: "Past Wash Sale Transactions",
            subtitle: "Primarily for Traders",
            description: "Understand the impact of past wash sale transactions on your portfolio. ProfiTree provides detailed reports that help you analyze how these transactions affect your cost basis and future trading strategies. This feature is especially useful for active traders aiming to fine-tune their tax-loss harvesting approach.",
            animation: washSaleAvoidanceAnimation, // Replace with the actual path to your image
        },
        {
            title: "Wash Sale Avoidance",
            subtitle: "Stay Ahead of Rules",
            description: "Avoid costly mistakes with real-time alerts to help you stay compliant with IRS wash sale rules. ProfiTree notifies you when potential trades might trigger wash sales, helping you take corrective action and maximize tax savings. This proactive approach saves you time and ensures better financial outcomes.",
            animation: washSaleAvoidanceAnimation, // Replace with the actual path to your image
        },
        {
            title: "Snapshot of Capital Gains & Losses",
            subtitle: "Current Year Overview",
            description: "Track your realized capital gains and losses throughout the year to make smarter investment decisions. ProfiTree provides a real-time snapshot of your taxable position, allowing you to plan strategic moves and optimize your tax outcomes. Stay informed and in control of your investment performance.",
            animation: growthAnimation, // Replace with the actual path to your image
        },

    ];

    return (
        <section className="features-section">
            {features.map((feature, index) => (
                <div
                    key={index}
                    className={`feature-item ${index % 2 === 0 ? "reverse" : ""}`}
                >
                    <div className="feature-text">
                        <h2>{feature.title}</h2>
                        <h4>{feature.subtitle}</h4>
                        <p>{feature.description}</p>
                    </div>
                    <div className="feature-image">
                        <Lottie
                            animationData={feature.animation} style={{ height: "250px", width: "250px" }}
                        />
                    </div>
                </div>
            ))}
        </section>
    );
}

export default FeaturesSectionNew;
