import React, { useState } from 'react';
import axios from 'axios';

const ContactUs = () => {
    const backendUrl = "https://profitree-backend-9482feeffc4a.herokuapp.com";
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [formStatus, setFormStatus] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormStatus('Submitting...');

        try {
            const response = await axios.post(`${backendUrl}/contact`, null, {
                params: {
                    name: formData.name,
                    email: formData.email,
                    message: formData.message
                },
            });

            if (response.status === 200 && response.data === 'Thank you! Your message has been received.') {
                console.log(response.data);
                setFormStatus('Thank you! Your message has been sent.');
                setFormData({ name: '', email: '', message: '' });
            } else {
                console.log(response);
                setFormStatus('Something went wrong. Please try again later.');
            }
        } catch (error) {
            setFormStatus('Error submitting the form. Please check your internet connection and try again.');
        }
    };

    const styles = {
        container: {
            padding: '30px',
            width: '100%',
            maxWidth: '350px',
            margin: '50px auto',
            borderRadius: '8px',
            backgroundColor: '#f8f9fa',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: '400px',
        },
        title: {
            fontSize: '24px',
            color: '#333',
            marginBottom: '20px'
        },
        formGroup: {
            textAlign: 'left',
            marginBottom: '15px',
        },
        label: {
            display: 'block',
            fontSize: '14px',
            color: '#333',
            marginBottom: '5px'
        },
        input: {
            width: '100%',
            padding: '10px',
            fontSize: '14px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            marginBottom: '10px'
        },
        button: {
            width: '100%',
            padding: '12px',
            fontSize: '16px',
            backgroundColor: '#264653',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
        },
        formStatus: {
            marginTop: '15px',
            fontSize: '16px',
            textAlign: 'center',
            color: '#264653',
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Contact Us</h2>
            <form onSubmit={handleSubmit}>
                <div style={styles.formGroup}>
                    <label htmlFor="name" style={styles.label}>Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        style={styles.input}
                    />
                </div>

                <div style={styles.formGroup}>
                    <label htmlFor="email" style={styles.label}>Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        style={styles.input}
                    />
                </div>

                <div style={styles.formGroup}>
                    <label htmlFor="message" style={styles.label}>Message</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        style={styles.input}
                    ></textarea>
                </div>

                <button type="submit" style={styles.button}>Submit</button>
            </form>
            {formStatus && <p style={styles.formStatus}>{formStatus}</p>}
        </div>
    );
};

export default ContactUs;