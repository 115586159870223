import React from "react";
import "./StatsSection.css";

const StatsSection = () => {
    return (
        <section className="two-frame-stats">
            <div className="stats-title">
                The Uncliamed <span className="highlight-red"> &nbsp; Tax-Loss</span> &nbsp; Problem
            </div>

            {/* Left Frame */}
            <div className="left-frame">
                <h2>$1.5 Trillion</h2>
                <strong>
                    <p1>left on the Table in 2022
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault(); // Prevent the default behavior
                                const newWindow = window.open(
                                    "https://www.irs.gov/pub/irs-soi/soi-a-inpre-id2401.pdf",
                                    "_blank"
                                );
                                if (!newWindow || newWindow.closed || typeof newWindow.closed === "undefined") {
                                    alert(
                                        "Unable to open the link. Please check your browser settings to allow pop-ups for this site."
                                    );
                                }
                            }}
                            className="source-link"
                        >
                            Source
                        </a>
                    </p1>
                </strong>
                <p2>

                </p2>
                <p2>
                    In <span className="highlight-red-small">2022 </span>, the S&P 500 experienced a sharp decline of <span className="highlight-red-small">19.4% </span>, making it one of the worst-performing years in recent history. Yet, despite this significant market downturn, only <span className="highlight-red-small">$30 billion in capital losses </span> were reported by taxpayers, compared to a staggering <span className="highlight-red-small">$1.6 trillion in capital gains </span>.

                </p2>
                <p2>
                    This highlights a <span className="highlight-red-small"> massive missed opportunity </span>. Many investors fail to take advantage of tax-loss harvesting, leaving billions of dollars in potential tax savings unclaimed.
                    <span className="highlight-green-small"> ProfiTree </span>helps investors close this gap, turning missed opportunities into real tax savings.
                </p2>
                {/* Add a simple graph/visual here */}
            </div>

            {/* Right Frame */}
            <div className="right-frame">
                <h3>The Struggle ...</h3>
                <ul>
                    <li><strong>Complex Wash Sale Rules:</strong> Wash sale rules can disallow losses if not managed
                        correctly. <span className="highlight-green-small"> ProfiTree </span> automates tracking to help you stay compliant while maximizing savings.
                    </li>
                    <li><strong>Lack of Tax-Loss Awareness:</strong> Many investors miss tax-saving opportunities simply
                        because they don’t know how or when to harvest losses.  <span className="highlight-green-small"> ProfiTree </span> simplifies the process with
                        actionable insights.
                    </li>
                    <li><strong>High Advisor Fees:</strong> Traditional financial advisors or robo-advisors charge
                        0.25%–1% of your portfolio annually. <span className="highlight-green-small"> ProfiTree  </span> empowers DIY investors to achieve the same
                        benefits at a fraction of the cost.
                    </li>
                </ul>
            </div>
        </section>
    );
};

export default StatsSection;
