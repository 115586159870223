import React, { useState } from "react";
import { useTransactions } from "./useTransactions";
import { useFilters } from "./useFilters";
import "./FrameStyles.css";
import loadingAnimation from "../assets/Loading.json";
import Lottie from "lottie-react";

const ITEMS_PER_PAGE = 5000000;

function WashSaleAvoidance({ username }) {
    const [expandedTransaction, setExpandedTransaction] = useState(null); // Track expanded card

    const toggleExpand = (index) => {
        setExpandedTransaction(expandedTransaction === index ? null : index); // Toggle expand/collapse
    };

    const { data: rawData, loading, error } = useTransactions(
        "transactions/getWashSaleAvoidanceCompanies",
        username
    );

    const data = Array.isArray(rawData) ? rawData : []; // Ensure `data` defaults to an empty array if invalid

    const {
        filteredData,
        totalPages,
        setYearFilter,
        setTickerFilter,
        currentPage,
        setCurrentPage,
    } = useFilters(data, ITEMS_PER_PAGE);

    if (loading) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Lottie
                    animationData={loadingAnimation}
                    style={{ height: "150px", width: "150px" }} // Adjust loader size
                />
            </div>
        );
    }
    if (error) return <div>Error: {error}, please contact support for help: support@profitree-tax.com</div>;

    return (
        <div>
            <h2>Wash Sale Avoidance</h2>
            <div className="filters">
                <label>
                    Year:
                    <select onChange={(e) => setYearFilter(e.target.value)}>
                        <option value="">All</option>
                        {[...new Set(data.map((d) => new Date(d.date).getFullYear()))].map(
                            (year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            )
                        )}
                    </select>
                </label>
                <label>
                    Ticker Symbol:
                    <input
                        type="text"
                        placeholder="Enter ticker symbol"
                        onChange={(e) => setTickerFilter(e.target.value)}
                    />
                </label>
            </div>

            {/* Desktop Table View */}
            <div className="table-container desktop-view">
                <table className="transactions-table">
                    <thead>
                    <tr>
                        <th>Account</th>
                        <th>Ticker</th>
                        <th>Last Sell Transaction Date</th>
                        <th>Days to Avoid</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredData.map((transaction, index) => (
                        <tr key={index}>
                            <td>{transaction.accountName}</td>
                            <td>{transaction.tickerSymbol}</td>
                            <td>{new Date(transaction.date).toLocaleDateString()}</td>
                            <td>{transaction.daysToAvoidWashSale?.toFixed(2)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            {/* Mobile Card View */}
            <div className="mobile-view">
                <div className="transaction-header-row">
                    <div className="header-item">Ticker</div>
                    <div className="header-item">Days to Avoid</div>
                </div>
                {filteredData.map((transaction, index) => (
                    <div
                        key={transaction.tickerSymbol + index}
                        className={`transaction-card ${
                            expandedTransaction === index ? "expanded" : ""
                        }`}
                    >
                        {/* Summary Section */}
                        <div
                            className="transaction-summary"
                            onClick={() => toggleExpand(index)}
                        >
                            <div className="transaction-header">
                                <span className="ticker">{transaction.tickerSymbol}</span>
                                <span className="days-to-avoid">
                                    {transaction.daysToAvoidWashSale?.toFixed(2)} days
                                </span>
                            </div>
                        </div>

                        {/* Details Section (conditionally displayed) */}
                        {expandedTransaction === index && (
                            <div className="transaction-details">
                                <p>
                                    <strong>Account Name:</strong> {transaction.accountName}
                                </p>
                                <p>
                                    <strong>Ticker:</strong> {transaction.tickerSymbol}
                                </p>
                                <p>
                                    <strong>Last Sell Date:</strong>{" "}
                                    {new Date(transaction.date).toLocaleDateString()}
                                </p>
                                <p>
                                    <strong>Days to Avoid:</strong>{" "}
                                    {transaction.daysToAvoidWashSale?.toFixed(2)} days
                                </p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default WashSaleAvoidance;
