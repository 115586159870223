import React from 'react';

function Footer() {
    return (
        <footer style={{ padding: '20px', textAlign: 'center', backgroundColor: '#264653', color: '#fff' }}>
            <p>©2024 ProfiTree. All rights reserved.</p>
            <nav>
                <a href="/about" style={{ color: '#f8f9fa', marginRight: '10px' }}>About</a>
                <a href="/terms" style={{ color: '#f8f9fa', marginRight: '10px' }}>Terms & Conditions</a>
                <a href="/privacy" style={{ color: '#f8f9fa' }}>Privacy Policy</a>
            </nav>
        </footer>
    );
}

export default Footer;
