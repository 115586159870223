import React, { useState } from 'react';
import './KnowledgeBase.css';

function KnowledgeBase() {
    const faqData = [
        {
            question: "What is Tax Loss Harvesting?",
            answer: "Tax loss harvesting is a strategy where investors sell assets at a loss to offset taxable gains, thereby reducing their overall tax bill. The proceeds from the sale can be reinvested into a similar asset to maintain the desired portfolio allocation.",
            example: "For example, if you bought a stock for $1,000 and it dropped in value to $700, you could sell it to realize a $300 loss. This loss can then offset $300 of taxable gains from other investments, lowering your overall tax burden."
        },
        {
            question: "How Does Tax Loss Harvesting Work?",
            answer: "Tax loss harvesting involves selling a security that has lost value and replacing it with a similar security to maintain your investment strategy. The realized loss can offset capital gains and up to $3,000 of ordinary income for the year.",
            example: "If you sold shares of Stock A at a $500 loss and had $500 in gains from Stock B, your net gain would be $0, effectively reducing your tax bill for the gains. You could then reinvest in a similar asset to keep your portfolio balanced."
        },
        {
            question: "What is a Wash Sale?",
            answer: "A wash sale occurs when you sell a security at a loss and repurchase the same or a substantially identical security within 30 days before or after the sale. The IRS disallows the loss for tax purposes in such cases, meaning it can't be used for tax deductions.",
            example: "For example, if you sold Stock X at a $200 loss, then repurchased Stock X two weeks later, the IRS would consider this a wash sale, and you wouldn’t be able to claim the $200 loss on your taxes."
        },
        {
            question: "Why Avoid Wash Sales?",
            answer: "Avoiding wash sales is essential because they disallow the tax deduction associated with the loss, reducing the effectiveness of a tax loss harvesting strategy. By timing trades carefully, investors can maximize the benefits of tax loss harvesting.",
            example: "If you sold an ETF at a loss and bought the same ETF again within 30 days, the loss would be disallowed. Instead, you could wait 31 days to repurchase the ETF or buy a different ETF that tracks a similar index to maintain your strategy without triggering a wash sale."
        },
        {
            question: "What Are the Benefits of Tax Loss Harvesting?",
            answer: "Tax loss harvesting can reduce your current year's tax liability, allow you to offset capital gains, and potentially increase after-tax returns over time. It can also help maintain a balanced portfolio by reinvesting the proceeds from sold assets.",
            example: "Imagine you realized $2,000 in gains from your portfolio this year. By harvesting $2,000 in losses, you offset these gains and avoid capital gains tax, allowing you to reinvest the tax savings."
        },
        {
            question: "How Often Should I Use Tax Loss Harvesting?",
            answer: "Tax loss harvesting opportunities can arise anytime during the year, especially during market downturns. Rather than waiting until year-end, investors can take advantage of losses as they occur to optimize tax benefits throughout the year. However, it’s essential to avoid triggering wash sales with each transaction to ensure that the harvested losses are eligible for tax deductions.",
            example: "For instance, if a particular stock or sector dips significantly mid-year, you might consider harvesting losses then, instead of waiting for year-end, to take advantage of tax savings immediately."
        },
        {
            question: "How Does Cost Basis Affect Capital Gains in Tax Loss Harvesting?",
            answer: "The cost basis method you choose can significantly impact the amount of capital gains or losses you report when selling an investment. Different cost basis methods may yield varying results in terms of gains or losses for tax purposes.",
            example: [
                "Assume an investor made the following consecutive purchases in a taxable account:",
                "- 1,500 shares at $20",
                "- 1,000 shares at $10",
                "- 1,250 shares at $8",
                "This brings the total investment to $50,000 across 3,750 shares, making the average cost basis $13.33 per share.",
                "",
                "Now, suppose the investor sells 1,000 shares at $19 per share. Here’s how different cost basis methods would affect the capital gain or loss:",
                <span className="highlight">"- Average Cost Basis:"</span>,
                "  Gain/Loss = (Sell Price - Average Cost) × Shares Sold",
                <span className="highlight">"  ($19 - $13.33) × 1,000 = $5,670 (capital gain)"</span>,
                "",
                <span className="highlight">"- First In, First Out (FIFO):"</span>,
                "  Gain/Loss = (Sell Price - Cost of First Shares Purchased) × Shares Sold",
                <span className="highlight">"  ($19 - $20) × 1,000 = -$1,000 (capital loss)"</span>,
                "",
                <span className="highlight">"- Last In, First Out (LIFO):"</span>,
                "  Gain/Loss = (Sell Price - Cost of Last Shares Purchased) × Shares Sold",
                <span className="highlight">"  ($19 - $8) × 1,000 = $11,000 (capital gain)"</span>,
                "",
                <span className="highlight">"- High Cost Basis:"</span>,
                "  Gain/Loss = (Sell Price - Highest Purchase Cost) × Shares Sold",
                <span className="highlight">"  ($19 - $20) × 1,000 = -$1,000 (capital loss)"</span>,
                "",
                <span className="highlight">"- Low Cost Basis:"</span>,
                "  Gain/Loss = (Sell Price - Lowest Purchase Cost) × Shares Sold",
                <span className="highlight">"  ($19 - $8) × 1,000 = $11,000 (capital gain)"</span>,
                "",
                "Conclusion: In this case, using the FIFO or High Cost Basis methods would result in a $1,000 capital loss, which could offset other capital gains or income for tax purposes. Using the Average Cost Basis method, however, results in a $5,670 capital gain, which would be subject to capital gains tax. Choosing the right cost basis method can impact your tax liability significantly."
            ]
        }


    ];

    return (
        <div className="knowledge-base">
            <h2 className="knowledge-heading">Tax Loss Harvesting FAQs</h2>
            <div className="faq-list">
                {faqData.map((faq, index) => (
                    <FaqItem key={index} question={faq.question} answer={faq.answer} example={faq.example} />
                ))}
            </div>
        </div>
    );
}

function FaqItem({ question, answer, example }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
        <div className="faq-item">
            <div className="faq-question" onClick={toggleOpen}>
                <h3>{question}</h3>
                <span>{isOpen ? "-" : "+"}</span>
            </div>
            {isOpen && (
                <div className="faq-content">
                    <p className="faq-answer">{answer}</p>
                    {Array.isArray(example) ? (
                        <div className="faq-example">
                            <strong>Example:</strong>
                            {example.map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                        </div>
                    ) : (
                        <p className="faq-example">
                            <strong>Example:</strong> {example}
                        </p>
                    )}
                </div>
            )}
        </div>
    );
}



export default KnowledgeBase;
