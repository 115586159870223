import React, { useState, useEffect } from 'react';
import './HeroSection.css'; // Your existing styles
import image1 from '../assets/01.png'; // Replace with your image paths
import image2 from '../assets/02.png';
import image3 from '../assets/03.png';
import image4 from '../assets/04.png';
import image5 from '../assets/05.png';

const HeroSection = () => {
    const images = [image1, image2, image3, image4, image5]; // Array of images
    const [currentImageIndex, setCurrentImageIndex] = useState(0); // Current image index

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [images.length]);

    return (
        <section className="hero-section">
            {/* Left Side: Text */}
            <div className="hero-text">
                <h1>Stop Letting Taxes Eat Your Gains </h1>
                <h2>Meet Automated Tax-Loss Harvesting.</h2>
                <p>Regardless of market conditions, your portfolio may hold opportunities to save on taxes through Tax-Loss Harvesting. By identifying and selling investments at a loss, you can offset gains or reduce your taxable income — putting more money back in your pocket to reinvest. ProfiTree simplifies this process, helping you uncover these opportunities and maximize your after-tax returns effortlessly.</p>
                {/* Add CTA Button */}
                <div className="cta-container">
                    <button
                        className="cta-button"
                        onClick={() => {
                            window.location.href = "/signup"; // Replace with your sign-up URL
                        }}
                    >
                        Get Started for Free
                    </button>

                    <button
                        className="learn-more-button"
                        onClick={() => {
                            window.location.href = "/learning"; // Replace with your learn TLH URL
                        }}
                    >
                        Learn More on TLH
                    </button>
                </div>
            </div>

            {/* Right Side: Image Slideshow */}
            <div className="hero-slideshow">
                <img
                    src={images[currentImageIndex]}
                    alt="ProfiTree Slideshow"
                    className="slideshow-image"
                />
            </div>
        </section>
    );
};

export default HeroSection;
