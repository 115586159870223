import React, {useState} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

const backendUrl = "https://profitree-backend-9482feeffc4a.herokuapp.com";

function SignUp({ onSignup }) { // Added onLogin prop
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState(""); // success or error
    const [showPassword, setShowPassword] = useState(false); // toggle password visibility
    const navigate = useNavigate();

    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
    };

    const handleCreateUser = async () => {
        const response = await axios.post(`${backendUrl}/users/create`, null, {
            params: {username, password},
        });
        if (response.status === 200 && response.data === true) {
            setMessage(response.data.message || "User created successfully! Please sign in.");
            setMessageType("success");
            onSignup(username); // Pass the username to parent App.js
            const accountResponse = await axios.post(`${backendUrl}/accounts/getList`, null, {
                params: { username },
            });
            if (accountResponse.status === 200 && accountResponse.data && accountResponse.data.length > 0) {
                navigate("/dashboard");
            }
            else {
                navigate("/account");
            }
        } else {
            setMessage(response.data.message || "Username already exists. Please try signing in.");
            setMessageType("error");
        }

    };


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Welcome to ProfiTree</h2>

            {message && (
                <div
                    style={{
                        ...styles.message,
                        backgroundColor: messageType === "success" ? "#d4edda" : "#f8d7da",
                        color: messageType === "success" ? "#155724" : "#721c24",
                    }}
                >
                    {message}
                </div>
            )}

            <div style={styles.formGroup}>
                <label style={styles.label}>Email</label>
                <input
                    type="text"
                    placeholder="me@example.com"
                    value={username}
                    onChange={handleInputChange(setUsername)}
                    style={styles.input}
                />
            </div>

            <div style={styles.formGroup}>
                <label style={styles.label}>Password</label>
                <div style={styles.passwordContainer}>
                    <input
                        type={showPassword ? "text" : "password"}
                        placeholder="password"
                        value={password}
                        onChange={handleInputChange(setPassword)}
                        style={{...styles.input, marginBottom: 0}}
                    />
                    <button onClick={togglePasswordVisibility} style={styles.showPasswordButton}>
                        {showPassword ? "Hide" : "Show"}
                    </button>
                </div>
            </div>

            <button onClick={handleCreateUser} style={styles.button}>
                Sign Up
            </button>
            <div style={styles.signUpText}>
                Already a client? <a href="/auth" style={styles.signUpLink}>Sign in</a>
            </div>
        </div>
    );
}

const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

const styles = {
    container: {
        padding: '30px',
        width: '100%', // Allow it to take up full width first
        maxWidth: '350px', // Set a larger maximum width
        margin: '50px auto', // Center horizontally and add some vertical spacing
        borderRadius: '8px',
        backgroundColor: '#f8f9fa',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '400px', // Ensure a decent height
    },
    title: {
        fontSize: '24px',
        color: '#333',
        marginBottom: '20px'
    },
    message: {
        padding: '10px',
        borderRadius: '5px',
        marginBottom: '15px',
        textAlign: 'center',
    },
    formGroup: {
        textAlign: 'left',
        marginBottom: '15px',
    },
    label: {
        display: 'block',
        fontSize: '14px',
        color: '#333',
        marginBottom: '5px'
    },
    input: {
        width: '100%',
        padding: '10px',
        fontSize: '14px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        marginBottom: '10px'
    },
    passwordContainer: {
        position: 'relative',
    },
    showPasswordButton: {
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        background: 'none',
        border: 'none',
        color: '#1B4D3E',
        cursor: 'pointer',
    },
    forgotPassword: {
        textAlign: 'right',
        fontSize: '12px',
        color: '#1B4D3E',
        marginTop: '5px'
    },
    button: {
        width: '100%',
        padding: '12px',
        fontSize: '16px',
        backgroundColor: '#264653',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    signUpText: {
        fontSize: '14px',
        color: '#264653',
        marginTop: '20px'
    },
    signUpLink: {
        color: '#264653',
        textDecoration: 'none',
        fontWeight: 'bold'
    },
};

export default SignUp;
