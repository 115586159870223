import { useState } from "react";

export const useFilters = (data, ITEMS_PER_PAGE) => {
    const [yearFilter, setYearFilter] = useState("");
    const [tickerFilter, setTickerFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    const filteredData = data.filter((item) => {
        return (
            (yearFilter ? new Date(item.date).getFullYear().toString() === yearFilter : true) &&
            (tickerFilter ? item.tickerSymbol === tickerFilter : true)
        );
    });

    const paginatedData = filteredData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

    return {
        filteredData: paginatedData,
        totalPages,
        setYearFilter,
        setTickerFilter,
        currentPage,
        setCurrentPage,
    };
};
